import { Link } from "react-router-dom";

export default function Error404() {
  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-80px)] bg-black">
      <div className="text-center">
        <h1 className="text-6xl font-title text-copy">404</h1>
        <p className="text-2xl font-text text-copy mb-4">Page not found</p>
        <Link to="/" className="text-copy hover:underline">
          Go back to home
        </Link>
      </div>
    </div>
  );
}
