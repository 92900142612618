import React from "react";
import { useParams, Navigate } from "react-router-dom";
import CarouselCustomer from "../components/Carousel";
import WebButton from "../components/webButton";
import { motion } from "framer-motion";

import customers from "../assets/data/customer.json";

export default function Customer() {
  const { id } = useParams();
  const customer = customers.find((customer) => customer.id === id);

  if (!customer) {
    return <Navigate to="/" replace />;
  }

  // Fonction pour afficher les technologies
  const renderTechnologies = (technologies) => {
    // Vérifier si 'technologies' est un tableau, sinon le transformer en tableau
    const techArray = Array.isArray(technologies)
      ? technologies
      : [technologies];

    return techArray.map((tech, index) => (
      <p className="font-text mt-2" key={index}>
        - {tech}
      </p>
    ));
  };

  const scaleVariants = {
    hidden: { scale: 0.8, y: 30, opacity: 0 },
    visible: {
      scale: 1,
      y: 0,
      opacity: 1,
      transition: { duration: 0.8, delay: 0.8 },
    },
  };

  return (
    <div className="xl:flex pt-36 bg-black xl:h-screen text-center xl:text-left">
      <div className="carousel xl:w-6/12 xl:ml-10">
        <CarouselCustomer />
        <div className="text-center xl:mb-0 mb-12 xl:text-2xl text-xl">
          <a
            className="text-white "
            href={customer.link}
            target="_blank"
            rel="noreferrer"
          >
            <WebButton />
          </a>
        </div>
      </div>
      <div className="flex flex-wrap xl:w-6/12" key={id}>
        <motion.div
          className="mx-5 text-white"
          variants={scaleVariants}
          initial="hidden"
          animate="visible"
        >
          <h1 className="text-5xl font-title">{customer.name}</h1>
          <p className="font-text mt-10 xl:mr-52 xl:mx-0 lg:mx-32 md:mx-14 sm:mx-10">
            {customer.description}
          </p>
          <div>
            <h3 className="font-title text-2xl mt-20 mb-5">Technology</h3>
            {renderTechnologies(customer.technology)}
          </div>
          <div>
            <h3 className="font-title text-2xl mt-20 mb-5"> Date</h3>
            <p className="font-text mb-10">{customer.date}</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
