import { motion } from "framer-motion";
import { FaLinkedinIn, FaGithub, FaTwitter } from "react-icons/fa";

export default function Footer() {
  const slideDownVariants = (delay) => ({
    hidden: { y: -30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 1, delay: delay },
    },
  });

  const slideVariants = {
    hidden: { y: -30, opacity: 0, rotate: 90 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 1, delay: 1 },
    },
  };

  const borderVariants = {
    hidden: { height: 0 },
    visible: {
      height: "8rem",
      transition: { duration: 1, delay: 0.5 },
    },
  };

  return (
    <footer>
      <div className="hidden xl:flex">
        <div className="fixed left-12 bottom-0 flex flex-col items-center">
          <motion.a
            href="https://twitter.com/F0XEEK/"
            target="_blank"
            rel="noreferrer"
            variants={slideDownVariants(1.3)}
            initial="hidden"
            animate="visible"
          >
            <FaTwitter className="h-6 w-6 mb-7 text-white hover:text-copy-lighter duration-300 ease-in-out cursor-pointer" />
          </motion.a>
          <motion.a
            href="https://github.com/fooxeek/"
            target="_blank"
            rel="noreferrer"
            variants={slideDownVariants(1.1)}
            initial="hidden"
            animate="visible"
          >
            <FaGithub className="h-6 w-6 mb-7 text-white hover:text-copy-lighter duration-300 ease-in-out cursor-pointer" />
          </motion.a>
          <motion.a
            href="https://www.linkedin.com/in/logan-bnbk/"
            target="_blank"
            rel="noreferrer"
            variants={slideDownVariants(0.9)}
            initial="hidden"
            animate="visible"
          >
            <FaLinkedinIn className="h-6 w-6 mb-4 text-white hover:text-copy-lighter duration-300 ease-in-out cursor-pointer" />
          </motion.a>

          <motion.div
            className="w-px h-32 bg-white"
            variants={borderVariants}
            initial="hidden"
            animate="visible"
          ></motion.div>
        </div>
        <div className="fixed right-0 bottom-0 flex flex-col items-center">
          <motion.a
            href="mailto:contact@loganben.com"
            className="mb-24 transform rotate-90 text-white hover:text-copy-lighter hover:mb-28 duration-300 ease-in-out cursor-pointer"
            variants={slideVariants}
            initial="hidden"
            animate="visible"
          >
            contact@loganben.com
          </motion.a>

          <motion.div
            className="w-px h-32 bg-white"
            variants={borderVariants}
            initial="hidden"
            animate="visible"
          ></motion.div>
        </div>
      </div>

      <div className="text-copy-lighter text-center bg-black pb-10">
        <div className="flex justify-center space-x-6 mb-4 xl:hidden">
          <a
            href="https://twitter.com/F0XEEK/"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter className="h-6 w-6 text-white hover:text-copy-lighter duration-300 ease-in-out cursor-pointer" />
          </a>
          <a href="https://github.com/fooxeek" target="_blank" rel="noreferrer">
            <FaGithub className="h-6 w-6 text-white hover:text-copy-lighter duration-300 ease-in-out cursor-pointer" />
          </a>
          <a
            href="https://www.linkedin.com/in/logan-bnbk/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn className="h-6 w-6 text-white hover:text-copy-lighter duration-300 ease-in-out cursor-pointer" />
          </a>
        </div>
        <a
          href="mailto:contact@loganben.com"
          className="text-white hover:text-copy-lighter duration-300 ease-in-out cursor-pointer xl:hidden"
        >
          contact@loganben.com
        </a>
        <p className=" pt-4">© 2023 | Logan B - All rights reserved</p>
      </div>
    </footer>
  );
}
