import { motion } from "framer-motion";

export default function WebButton() {
  const scaleVariants = {
    hidden: { scale: 0.8, y: 30, opacity: 0 },
    visible: {
      scale: 1,
      y: 0,
      opacity: 1,
      transition: { duration: 0.8, delay: 0.6 },
    },
  };

  return (
    <motion.div
      className="flex items-center justify-center"
      variants={scaleVariants}
      initial="hidden"
      animate="visible"
    >
      <RoundedSlideButton />
    </motion.div>
  );
}

const RoundedSlideButton = () => {
  return (
    <button
      className={`
        relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
        border-white px-4 py-2 font-semibold
        uppercase text-white transition-all duration-500
        
        before:absolute before:inset-0
        before:-z-10 before:translate-x-[150%]
        before:translate-y-[150%] before:scale-[2.5]
        before:rounded-[100%] before:bg-white
        before:transition-transform before:duration-1000
        before:content-[""]

        hover:scale-105 hover:text-primary-light
        hover:before:translate-x-[0%]
        hover:before:translate-y-[0%]
        active:scale-95`}
    >
      <span>Visit Website</span>
    </button>
  );
};
