import Hero from "../components/Hero";
import RevealCards from "../components/Card";
import Experience from "../components/Experience";
import Forms from "../components/Forms";

export default function Home() {
  return (
    <div>
      <Hero />
      <RevealCards />
      <Experience />
      <Forms />
    </div>
  );
}
