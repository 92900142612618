import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import polarFi from "../assets/img/banner/polarfi.png";
import kasa from "../assets/img/banner/kasa.png";
import booki from "../assets/img/banner/booki.png";
import ohmyfood from "../assets/img/banner/ohmyfood.png";
import kanap from "../assets/img/banner/kanap.png";

export default function RevealCards() {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const titleVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  const borderVariants = {
    hidden: { width: 0 },
    visible: {
      width: "15rem",
      transition: { duration: 1 },
    },
  };

  const slideUpVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: 1 },
    },
  };

  return (
    <section className="px-10 pt-8 pb-20 bg-black" id="work">
      <div className="max-w-5xl mx-auto">
        <motion.h1
          className="text-2xl text-white font-bold pb-4 mb-1 w-60 uppercase"
          variants={titleVariants}
          initial="hidden"
          animate={controls}
        >
          Work
        </motion.h1>
        <motion.div
          className="border-b-2 w-60 mb-10"
          variants={borderVariants}
          initial="hidden"
          animate={controls}
        ></motion.div>
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
          variants={slideUpVariants}
          initial="hidden"
          animate={controls}
        >
          <Card
            title="PolarFi"
            description="Curently at the post of CTO at PolarFi, I am in charge of the development of the platform and the management of the technical team."
            imgSrc={polarFi}
            id="polarfi"
          />
          <Card
            title="Kasa"
            description="Worked as Front-End Developer for Kasa, a housing search site."
            imgSrc={kasa}
            id="kasa"
          />
          <Card
            title="Booki"
            description="Worked as Front-End Developer for Booki, a booking site for hotels."
            imgSrc={booki}
            id="booki"
          />

          <Card
            title="OhMyFood"
            description="Worked as Front-End Developer for OhMyFood, find your restaurant."
            imgSrc={ohmyfood}
            id="ohmyfood"
          />
          <Card
            title="Kanap"
            description="Worked as Front-End Developer for Kanap, an e-commerce site for sofas."
            imgSrc={kanap}
            id="kanap"
          />
        </motion.div>
      </div>
    </section>
  );
}

const Card = ({ imgSrc, title, description, id }) => {
  return (
    <motion.div whileHover="hover" className="w-full h-[300px] relative">
      <div className="h-1/2 p-6 flex flex-col justify-center bg-black">
        <h3 className="text-xl mb-2 font-semibold text-white">{title}</h3>
        <p className="text-sm font-light text-slate-300">{description}</p>
      </div>
      <motion.div
        variants={{
          hover: {
            top: "50%",
            right: "50%",
          },
        }}
        className="absolute inset-0 bg-slate-200 z-10"
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Link
        to={`/customer/${id}`}
        className="w-1/2 h-1/2 absolute bottom-0 right-0 z-0 grid place-content-center bg-white text-black hover:text-copy-light transition-colors"
      >
        <div className="flex items-center">
          <span className="text-xs">MORE</span>
          <FiArrowUpRight className="text-lg" />
        </div>
      </Link>
    </motion.div>
  );
};
