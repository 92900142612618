import React from "react";
import { motion } from "framer-motion";
import profilePic from "../assets/img/logo.png";

export default function Hero() {
  const titleVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1, delay: 0.3 },
    },
  };

  const borderVariants = {
    hidden: { width: 0 },
    visible: {
      width: "15rem",
      transition: { duration: 1, delay: 0.5 },
    },
  };

  const slideUpVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 1, delay: 0.7 },
    },
  };

  return (
    <section
      id="hero"
      className="bg-black h-screen flex justify-center items-center"
    >
      <div className="h-full max-w-5xl h-[50%] mx-10 flex justify-center items-center">
        <div className="m-auto">
          <motion.h1
            className="text-2xl text-white font-bold pb-4 mb-1 w-60 uppercase"
            variants={titleVariants}
            initial="hidden"
            animate="visible"
          >
            Hello
          </motion.h1>
          <motion.div
            className="border-b-2 w-60 mb-10"
            variants={borderVariants}
            initial="hidden"
            animate="visible"
          ></motion.div>
          <motion.div
            className="flex justify-center items-start"
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="mr-10 object-cover groupProfile">
              <img
                src={profilePic}
                alt="profile"
                className="h-[150px] w-[150px] object-contain profilePic"
              />
            </div>
            <div>
              <h2 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-white font-bold mb-10">
                I'm Logan, Web Developer
              </h2>
              <p className="text-sm md:text-md lg:text-lg xl:text-2xl text-white font-bold max-w-5xl font-text">
                I work with startups and companies for a few years, I'm
                passionate about new technologies and their ability to improve
                business productivity. You are worried about the SEO of your
                site, don't worry, I take care of that too.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
