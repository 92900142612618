import { motion, useMotionValue, useMotionValueEvent } from "framer-motion";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import customers from "../assets/data/customer.json";

export default function CarouselCustomer() {
  const { id } = useParams();
  const customer = customers.find((customer) => customer.id === id);

  const [order, setOrder] = useState(["front", "middle", "back"]);
  const dragProgress = useMotionValue(0);

  const handleDragEnd = () => {
    const x = dragProgress.get();
    if (x <= -50) {
      setOrder((prevOrder) => {
        const orderCopy = [...prevOrder];
        orderCopy.unshift(orderCopy.pop());
        return orderCopy;
      });
    }
  };

  const scaleVariants = {
    hidden: { scale: 0.8, y: 30, opacity: 0 },
    visible: {
      scale: 1,
      y: 0,
      opacity: 1,
      transition: { duration: 0.8, delay: 0.4 },
    },
  };

  useEffect(() => {
    const FIVE_SECONDS = 5000;
    const intervalRef = setInterval(() => {
      const x = dragProgress.get();
      if (x === 0) {
        setOrder((prevOrder) => {
          const orderCopy = [...prevOrder];
          orderCopy.unshift(orderCopy.pop());
          return orderCopy;
        });
      }
    }, FIVE_SECONDS);

    return () => clearInterval(intervalRef);
  }, [dragProgress]);

  return (
    <div className="grid place-content-center overflow-hidden bg-black px-8 py-12 xl:py-24 text-slate-50">
      <motion.div
        whileTap={{ scale: 0.985 }}
        className="relative h-[450px] w-[350px] md:-ml-[155px]"
        variants={scaleVariants}
        initial="hidden"
        animate="visible"
      >
        {customer.pictures.map((picture, index) => (
          <Carousel
            key={index}
            imgUrl={picture}
            handleDragEnd={handleDragEnd}
            dragProgress={dragProgress}
            position={order[index]}
          />
        ))}
      </motion.div>
    </div>
  );
}

const Carousel = ({ handleDragEnd, dragProgress, position, imgUrl, name }) => {
  const [dragging, setDragging] = useState(false);

  const dragX = useMotionValue(0);

  useMotionValueEvent(dragX, "change", (latest) => {
    if (typeof latest === "number" && dragging) {
      dragProgress.set(latest);
    } else {
      dragProgress.set(0);
    }
  });

  const onDragStart = () => setDragging(true);

  const onDragEnd = () => {
    setDragging(false);
    handleDragEnd();
  };

  const x = position === "front" ? "0%" : position === "middle" ? "33%" : "66%";
  const rotateZ =
    position === "front" ? "-6deg" : position === "middle" ? "0deg" : "6deg";
  const zIndex = position === "front" ? "2" : position === "middle" ? "1" : "0";

  const draggable = position === "front";

  return (
    <motion.div
      style={{ zIndex, x: dragX }}
      animate={{ rotate: rotateZ, x }}
      drag
      dragElastic={0.35}
      dragListener={draggable}
      dragConstraints={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      transition={{ duration: 0.35 }}
      className={`absolute left-0 top-0 grid select-none place-content-center space-y-6 rounded-2xl bg-primary-copy-light/20 shadow-xl backdrop-blur-md ${
        draggable ? "cursor-grab active:cursor-grabbing" : ""
      } h-[300px] w-[200px] md:h-[400px] md:w-[300px] xl:h-[350px] xl:w-[250px] 2xl:h-[400px] 2xl:w-[300px]`}
    >
      <img
        src={imgUrl}
        alt={name}
        className="pointer-events-none object-cover object-center h-[298px] w-[198px] md:h-[398px] md:w-[298px] xl:h-[348px] xl:w-[248px] 2xl:h-[398px] 2xl:w-[298px] rounded-2xl"
      />
    </motion.div>
  );
};
