import Cal from "@calcom/embed-react";
import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

export default function Forms() {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1700) {
        setIsInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const slideUpVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: 1 },
    },
  };

  return (
    <>
      <section className="p-8 bg-black" id="contact">
        <motion.div
          variants={slideUpVariants}
          initial="hidden"
          className="bg-black"
          animate={controls}
        >
          <Cal
            calLink="logan-b/meeting"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
            config={{ layout: "month_view" }}
          />
        </motion.div>
      </section>
    </>
  );
}
